import React, { useState } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Pagination from "@atlaskit/pagination";
import EmptyState from "@atlaskit/empty-state";
import NoRulesIcon from "./noRulesIcon.png";
import "./AutomationsTable.css";
import LoadingTracker from "../../../../components/LoadingTracker/LoadingTracker";
import Profile from "../../../../components/Profile/Profile";
import moment from "moment";
import { Link } from "react-router-dom";
import { useAutomationsQuery } from "../../../../clients/automations/automation-queries";

import { Stack } from "@atlaskit/primitives";
import ErrorSection from "../../../../components/ErrorSection";
import { nextRun } from "../../nextRun";
import AutomationMenu from "../automation-menu";

const ITEMS_PER_PAGE = 10;

const RulesTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data, error, isLoading } = useAutomationsQuery(
    currentPage,
    ITEMS_PER_PAGE,
  );

  const { data: automations, total: totalItems } = data || {};
  const totalPages = Math.ceil((totalItems || 0) / ITEMS_PER_PAGE);

  const tableRows = automations
    ? automations.map((automation, index) => ({
        key: `row-${index}-${automation.id}`,
        cells: [
          {
            key: `id-${automation.id}`,
            content: (
              <Link to={`/automation/${automation.id}`}>{automation.name}</Link>
            ),
            testId: `cell-name-${automation.id}`,
          },
          {
            key: `lastRun-${automation.id}`,
            content: automation.lastRun
              ? `${moment(automation.lastRun).local().format("LLL")}`
              : "Never",
            testId: `cell-lastRun-${automation.id}`,
          },
          {
            key: `next-run-${automation.id}`,
            content: nextRun(automation),
            testId: `cell-next-run-${automation.id}`,
          },
          {
            key: `trigger-type-${automation.id}`,
            content: (
              <>
                <span className="rules-table__trigger_name">{`${automation.triggerDisplayName}`}</span>
              </>
            ),
            testId: `cell-created-${automation.id}`,
          },
          {
            key: `action-type-${automation.id}`,
            content: <>{`${automation.typeDisplayName}`}</>,
            testId: `cell-created-${automation.id}`,
          },
          {
            key: `ownerAccountId-${automation.id}`,
            content: (
              <Profile
                atlassianAccountId={automation.ownerAccountId}
                showAvatar
                showName
                size="medium"
              />
            ),
            testId: `cell-ownerProfile-${automation.id}`,
          },
          {
            key: `actions-${automation.id}`,
            content: (
              <>
                <AutomationMenu showView automation={automation!} />
              </>
            ),
          },
        ],
      }))
    : [];
  return (
    <div className="rules-table-container">
      <div className="rules-table">
        {error && <ErrorSection />}
        {isLoading ? (
          <LoadingTracker />
        ) : (
          <>
            <div className="empty-state" data-testid="empty-state-component">
              {automations && automations.length === 0 && (
                <EmptyState
                  header="No rules were found"
                  description="Click the Add Rule button to get started!"
                  imageUrl={NoRulesIcon}
                  imageWidth={100}
                />
              )}
            </div>
            {automations && automations.length > 0 && (
              <DynamicTable
                isFixedSize
                head={{
                  cells: [
                    {
                      key: "ID",
                      content: "Rule Name",
                      isSortable: false,
                      testId: "cell-name",
                    },
                    {
                      key: "LastRun",
                      content: "Last Run",
                      isSortable: false,
                      testId: "cell-lastRun",
                    },
                    {
                      key: "nextRun",
                      content: "Next Run",
                      isSortable: false,
                      testId: "cell-next-run",
                    },
                    {
                      key: "trigger-type",
                      content: "Trigger Type",
                      isSortable: false,
                      testId: "cell-trigger-type",
                    },
                    {
                      key: "action-type",
                      content: "Action Type",
                      isSortable: false,
                      testId: "cell-action-type",
                    },
                    {
                      key: "ownerId",
                      content: "Created by",
                      isSortable: false,
                      testId: "cell-owner",
                    },
                    {
                      key: "actions",
                      content: "Actions",
                      isSortable: false,
                      testId: "cell-enabled",
                    },
                  ],
                }}
                rows={tableRows}
              />
            )}
          </>
        )}
        {automations && automations.length > 0 && (
          <div className="pagination-wrapper">
            <Stack alignInline="center" space="space.100">
              <Pagination
                nextLabel="Next"
                label="Page"
                pageLabel="Page"
                pages={Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                )}
                previousLabel="Previous"
                onChange={(event, page: number) => setCurrentPage(page - 1)}
                data-testid="pagination"
              />
              <div>
                <small>{totalItems} rule(s) found</small>
              </div>
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default RulesTable;
