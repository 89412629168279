import React from "react";
import { LoadingButton as Button } from "@atlaskit/button";
import { Automation, AutomationListItem } from "../../../types";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";

export const DeleteModal = ({
  isOpen,
  closeModal,
  automation,
  handleDelete,
}: {
  isOpen: boolean;
  closeModal: () => void;
  automation: Automation | AutomationListItem;
  handleDelete: () => void;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <ModalHeader>
            <ModalTitle appearance="warning">
              Delete the rule "{automation.name}"?
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            We will schedule deletion of all related configuration settings,
            artifacts, and results. This can’t be undone.
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              appearance="warning"
              onClick={() => {
                closeModal();
                handleDelete();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
