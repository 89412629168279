import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { getConfigStatus } from ".";
import { ConfigurationStatus } from "../../types";

export const useConfigStatusQuery = () => {
  return useQuery<unknown, Error, ConfigurationStatus, string[]>({
    queryKey: ["config"],
    queryFn: () => getConfigStatus(),
    placeholderData: keepPreviousData,
  });
};
