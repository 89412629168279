import React from "react";
import { useGroupQuery } from "../../../clients/jira/jira-queries";
import { Box, Inline, xcss } from "@atlaskit/primitives";
import InlineMessage from "@atlaskit/inline-message";

export interface GroupNameProps {
  groupId: string;
}

export const GroupName: React.FC<GroupNameProps> = ({ groupId }) => {
  const { data: group, isLoading, error } = useGroupQuery(groupId);

  const showError = !isLoading && (error || !group?.found);

  const loadingStyle = xcss({
    backgroundColor: "color.background.neutral",
    borderRadius: "border.radius",
    padding: "space.025",
    paddingLeft: "space.100",
    paddingRight: "space.100",
    color: "color.text.subtle",
    fontSize: "85%",
    display: "inline-block",
  });

  const errorStyle = xcss({
    backgroundColor: "color.background.warning",
    borderRadius: "border.radius",
    padding: "space.025",
    paddingLeft: "space.100",
    paddingRight: "space.100",
    color: "color.text.subtle",
    fontSize: "font.size.small",
    display: "inline-block",
  });

  const normalStyle = xcss({ padding: "space.025", display: "inline-block" });
  return (
    <Inline alignBlock="center" space="space.025">
      <Box
        xcss={isLoading ? loadingStyle : showError ? errorStyle : normalStyle}
        as="div"
      >
        {isLoading && "Loading..."}
        {!isLoading && group?.name}
        {showError && groupId}
      </Box>
      {showError && (
        <InlineMessage
          placement="right"
          appearance="warning"
          iconLabel="Unable to load group"
        >
          <p>
            Unable to load name for group (<strong>{groupId}</strong>)
            {!error && !group?.found ? " as it was not found." : "."}
          </p>
        </InlineMessage>
      )}
    </Inline>
  );
};
