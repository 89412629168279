import { ErrorMessage, Field, FormSection } from "@atlaskit/form";
import React from "react";
import { ValueType } from "@atlaskit/select";
import GroupSelect from "../../../../components/select/GroupSelect";

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
}

const RemoveGroupsConfig: React.FC<Props> = ({ name }) => {
  return (
    <>
      <FormSection title="If" description="A user is not in this group.">
        <Field<ValueType<Option, true>>
          name="config.sourceGroupId"
          label="Group"
        >
          {({ fieldProps: { ...rest }, error }) => (
            <>
              <GroupSelect rest={rest} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </FormSection>
      <FormSection
        title="Then"
        description="Also remove the user from this group."
      >
        <Field<ValueType<Option, true>>
          name="config.targetGroupId"
          label="Group"
        >
          {({ fieldProps: { ...rest }, error }) => (
            <>
              <GroupSelect rest={rest} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>
      </FormSection>
    </>
  );
};

export default RemoveGroupsConfig;
