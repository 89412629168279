import { rest } from "msw";
import { Automation, ConfigurationStatus, SaveApiKeyRequest } from "../types";
import { AutomationListItem } from "../types";
import {
  getAutomationsResponse,
  getUserResponse,
  getConfigStatusResponse,
  groupsResponse,
  getAutomationResponse,
  updateMockAutomation,
  exampleScheduledExecutionLogs,
} from "./test_data";
import { GroupResponse } from "../clients/jira";

let apiKeyInMemory: string | null = "Example API Key";

export const handlers = [
  // To JIRA.

  rest.get<GroupResponse>("/rest/api/3/groups/picker", (req, res, ctx) => {
    const query = req.url.searchParams.get("query") || "";
    const filtered = groupsResponse.groups.filter((group) =>
      group.name.includes(query),
    );
    return res(ctx.json({ ...groupsResponse, groups: filtered }));
  }),

  rest.get<GroupResponse>("/rest/api/3/group/bulk", (req, res, ctx) => {
    const groupId = req.url.searchParams.get("groupId") || "";
    const filtered = groupsResponse.groups.filter(
      (group) => group.groupId === groupId,
    );
    const result = {
      maxResults: 1,
      startAt: 0,
      total: filtered.length,
      isLast: true,
      values: filtered,
    };
    return res(ctx.json(result));
  }),

  rest.get<ConfigurationStatus>("/rest/api/3/user", (req, res, ctx) => {
    return res(ctx.json(getUserResponse));
  }),

  // Configuration

  rest.get<ConfigurationStatus>("/api/config/status", async (req, res, ctx) => {
    await new Promise((r) => setTimeout(r, 1000));
    if (apiKeyInMemory === null) {
      return res(ctx.json({ getConfigStatusResponse, apiKeyProvided: false }));
    }
    return res(ctx.json(getConfigStatusResponse));
  }),

  rest.delete<any>("/api/config", async (req, res, ctx) => {
    await new Promise((r) => setTimeout(r, 1000));
    apiKeyInMemory = null;
    return res(ctx.status(200));
  }),

  rest.post<SaveApiKeyRequest>("/api/config", async (req, res, ctx) => {
    const { apiKey } = req.body;
    apiKeyInMemory = apiKey;
    await new Promise((r) => setTimeout(r, 2000));
    return res(ctx.status(200));
  }),

  // Automation

  rest.delete<any>("/api/automations/:id", (req, res, ctx) => {
    return res(ctx.status(204));
  }),

  rest.put<AutomationListItem, any>("/api/automations/:id", (req, res, ctx) => {
    const id = req?.params.id || "";

    updateMockAutomation(id, req.body);

    return res(
      ctx.json({
        ...getAutomationResponse(id),
        enabled: req.body.enabled,
      }),
    );
  }),

  rest.get<Automation, any>("/api/automations/:id", (req, res, ctx) => {
    const id = req?.params.id || "";
    return res(ctx.json(getAutomationResponse(id)));
  }),

  rest.get<any, any>("/api/automations/:id/executions", (req, res, ctx) => {
    return res(ctx.json(exampleScheduledExecutionLogs));
  }),

  rest.get<{ data: AutomationListItem[]; total: number }, any>(
    "/api/automations",
    (req, res, ctx) => {
      const limit = Number(req.url.searchParams.get("limit")) || 10;
      const page = Number(req.url.searchParams.get("page")) || 0;

      const start = page * limit;
      const end = start + limit;

      return res(
        ctx.json({
          data: getAutomationsResponse.data.slice(start, end),
          total: getAutomationsResponse.total,
        }),
      );
    },
  ),
];
