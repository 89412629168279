import React from "react";
import SectionMessage from "@atlaskit/section-message";

interface CustomSectionMessageProps {
  title: string;
  appearance: "success" | "error" | "warning";
  children: React.ReactNode;
}

const CustomSectionMessage: React.FC<CustomSectionMessageProps> = ({
  title,
  appearance,
  children,
}) => (
  <SectionMessage
    testId="custom-section-message"
    title={title}
    appearance={appearance}
  >
    {children}
  </SectionMessage>
);

export default CustomSectionMessage;
