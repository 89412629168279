import React from "react";
import { useAutomationExecutionsQuery } from "../../../clients/automations/automation-queries";
import { format, formatDistance } from "date-fns";
import Lozenge from "@atlaskit/lozenge";

interface ExecutionsTableProps {
  automationId: string;
}

const ExecutionsTable: React.FC<ExecutionsTableProps> = ({ automationId }) => {
  const {
    data: executions,
    isLoading,
    isError,
  } = useAutomationExecutionsQuery(automationId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error occurred while fetching executions.</div>;
  }

  return (
    <>
      <table>
        <tbody>
          {executions?.data?.map((execution) => (
            <tr key={execution.id}>
              <td>
                <Lozenge
                  appearance={execution.succeeded ? "success" : "removed"}
                >
                  {execution.succeeded ? "SUCCESS" : "FAILED"}
                </Lozenge>
              </td>
              <td>{format(execution.timeStarted, "yyyy-MM-dd HH:mm")}</td>
              <td>
                {formatDistance(execution.timeStarted, execution.timeFinished)}
              </td>
              <td>{execution.errorMessage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ExecutionsTable;
