import axios from "axios";

interface JavascriptAPI {
  request: any;
  context: {
    getToken: (callback: (token: string) => void) => void;
  };
}

declare var AP: JavascriptAPI;

interface TokenHeaders {
  Authorization?: string;
}

export const getTokenHeaders = (): Promise<TokenHeaders> => {
  return new Promise((resolve) => {
    try {
      AP.context.getToken((token: string) => {
        resolve({ Authorization: `JWT ${token}` });
      });
    } catch (error) {
      resolve({});
    }
  });
};

export async function jsApiRequest(options: {
  url: string;
  type: "GET" | "POST";
  data?: any;
}): Promise<any> {
  if (AP && AP.request) {
    return await new Promise((resolve, reject) =>
      AP.request({
        success: (responseText: any) => {
          resolve(JSON.parse(responseText));
        },
        error: (error: any) => {
          reject(error);
        },
        ...options,
      }),
    );
  }

  // This is a fake implementation of the request function
  const response = await axios.request({
    url: options.url,
    method: options.type,
    data: options.data,
  });

  return response.data;
}
