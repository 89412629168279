import React from "react";

import { IconButton } from "@atlaskit/button/new";
import MoreIcon from "@atlaskit/icon/glyph/more";

import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import {
  Automation,
  AutomationListItem,
  AutomationSchedule,
} from "../../../types";
import {
  useDeleteAutomation,
  useDeleteScheduleAutomation,
  useScheduleAutomation,
} from "../../../clients/automations/automation-mutations";
import useSmolFlags from "../../Layouts/flag-helper";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../components/delete-modal";
import { RescheduleModal } from "../components/reschedule-modal";

const AutomationMenu = ({
  automation,
  showView = false,
  hideEnableDisable = false,
}: {
  automation: Automation | AutomationListItem;
  showView?: boolean;
  hideEnableDisable?: boolean;
}) => {
  const navigate = useNavigate();

  const [addFlag] = useSmolFlags();
  const { mutate: rescheduleMutation } = useScheduleAutomation();

  const { mutate: deleteScheduleMutation } = useDeleteScheduleAutomation();

  async function scheduleAutomation(
    automation: AutomationListItem | Automation,
    schedule?: AutomationSchedule,
    startDate?: string,
  ) {
    return rescheduleMutation(
      {
        automationId: automation!.id,
        schedule: schedule || automation!.schedule,
        startDate: startDate || automation!.startDate,
      },
      {
        onSuccess: () => {
          addFlag(
            "Automation scheduled",
            "Automation was scheduled successfully",
            "success",
          );
        },
        onError: () => {
          addFlag(
            "Error scheduling automation",
            "Something went wrong, try again later or contact support.",
            "warning",
          );
        },
      },
    );
  }

  const { mutate: deleteMutation } = useDeleteAutomation();

  function deleteAutomation() {
    deleteMutation(`${automation?.id}`, {
      onSuccess: () => {
        addFlag(
          "Automation deleted",
          "Automation was deleted successfully",
          "success",
        );
        navigate("/automation");
      },
      onError: () => {
        addFlag(
          "Error deleting automation",
          "Something went wrong, try again later or contact support.",
          "warning",
        );
      },
    });
  }

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  function deleteAutomationSchedule(automationId: string) {
    deleteScheduleMutation(`${automationId}`, {
      onSuccess: () => {
        addFlag(
          "Automation disabled",
          "Automation was updated successfully",
          "success",
        );
      },
      onError: () => {
        addFlag(
          "Error disabling automation",
          "Something went wrong, it may have been running, try again later or contact support.",
          "warning",
        );
      },
    });
  }

  const [scheduleModalOpen, setScheduleModalOpen] = React.useState(false);

  return (
    <>
      <DropdownMenu<HTMLButtonElement>
        trigger={({ triggerRef, ...props }) => (
          <IconButton
            {...props}
            icon={MoreIcon}
            label="more"
            ref={triggerRef}
          />
        )}
        shouldRenderToParent
      >
        <DropdownItemGroup>
          {showView && (
            <DropdownItem
              onClick={() => navigate(`/automation/${automation.id}`)}
            >
              View
            </DropdownItem>
          )}
          <DropdownItem
            isDisabled={automation.running}
            onClick={() => {
              setScheduleModalOpen(true);
            }}
          >
            Rechedule
          </DropdownItem>
          {!hideEnableDisable && (
            <DropdownItem
              isDisabled={automation.running}
              onClick={() => {
                scheduleAutomation(automation);
              }}
            >
              Run Now
            </DropdownItem>
          )}
          {automation.enabled && !hideEnableDisable && (
            <DropdownItem
              isDisabled={automation.running}
              onClick={() => {
                deleteAutomationSchedule(automation.id);
              }}
            >
              Disable
            </DropdownItem>
          )}
          <DropdownItem
            isDisabled={automation.running}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
      <RescheduleModal
        isOpen={scheduleModalOpen}
        automation={automation}
        onSubmit={(startDate, schedule) =>
          scheduleAutomation(automation, schedule, startDate)
        }
        closeModal={() => setScheduleModalOpen(false)}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        automation={automation}
        handleDelete={deleteAutomation}
        closeModal={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default AutomationMenu;
