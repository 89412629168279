import moment from "moment";
import {
  Automation,
  AutomationListItem,
  AutomationPageResponse,
} from "../types";
import { uniqueId } from "lodash";

export const getConfigStatusResponse = {
  apiKeyProvided: true,
  createdBy: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
  createdDate: "2024-01-06T23:54:48.497759",
  status: "AUTHORIZED",
};

export const exampleScheduledExecutionLogs = {
  data: [
    {
      id: 1,
      timeStarted: "2024-02-10T21:08:09.885338",
      timeFinished: "2024-02-10T22:08:09.885338",
      succeeded: true,
      durationMs: 900000,
      errorMessage: null,
    },
    {
      id: 2,
      timeStarted: "2024-02-10T22:08:09.885338",
      timeFinished: "2024-02-10T23:08:09.885338",
      succeeded: true,
      durationMs: 60000,
      errorMessage: null,
    },
    {
      id: 3,
      timeStarted: "2024-02-10T23:04:09.885338",
      timeFinished: "2024-02-10T23:04:09.885338",
      succeeded: false,
      durationMs: 40,
      errorMessage: "Group not found",
    },
  ],
  total: 3,
};

export const groupsResponse = {
  header: "Showing 20 of 25 matching groups",
  total: 25,
  groups: [
    {
      name: "all-users",
      html: "<b>a</b>ll-users",
      groupId: "276f955c-63d7-42c8-9520-92d01dca0625",
    },
    {
      name: "example-target-group",
      html: "<b>e</b>xample-target-group",
      groupId: "6e87dc72-4f1f-421f-9382-2fee8b652487",
    },
  ],
};

const exampleConfigs: any[] = [
  {
    type: "SYNC_MEMBERS_ADD",
    sourceGroupId: "276f955c-63d7-42c8-9520-92d01dca0625",
    targetGroupId: "6e87dc72-4f1f-421f-9382-2fee8b652487",
  },
  {
    type: "SYNC_MEMBERS_REMOVE",
    sourceGroupId: "276f955c-63d7-42c8-9520-92d01dca0625",
    targetGroupId: "6e87dc72-4f1f-421f-9382-2fee8b652487",
  },
];

export const automations: Automation[] = [
  {
    id: uniqueId("rrrannndoomm-"),
    name: "No start date",
    description: "A big long description about this example",
    created: "2024-02-10T23:05:41.179869",
    nextRun: moment().utc().toISOString(),
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: true,
    typeDisplayName: "Group Sync, Remove Members",
    triggerDisplayName: "Daily",
    config: exampleConfigs[1],
    schedule: "DAILY",
  },
  {
    id: uniqueId("rrrannndoomm-"),
    name: "Scheduled to run in 6 days",
    description: "A big long description about this example",
    created: "2024-02-10T23:05:41.179869",
    startDate: moment().utc().add(6, "days").toISOString(),
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: true,
    typeDisplayName: "Group Sync, Remove Members",
    triggerDisplayName: "Daily",
    config: exampleConfigs[1],
    schedule: "DAILY",
  },
  {
    id: uniqueId("rrrannndoomm-"),
    name: "Scheduled to run 6 day ago, last run just now",
    description: "A big long description about this example",
    nextRun: moment().utc().add(1, "days").toISOString(),
    lastRun: moment().utc().subtract(10, "minutes").toISOString(),
    created: moment().utc().subtract(7, "days").toISOString(),
    startDate: moment().utc().subtract(6, "days").toISOString(),
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: true,
    typeDisplayName: "Group Sync, Remove Members",
    triggerDisplayName: "Daily",
    schedule: "DAILY",
    config: exampleConfigs[1],
  },
  {
    id: uniqueId("rrrannndoomm-"),
    name: "Ready to run in the next 5 mins.",
    description: "A big long description about this example",
    nextRun: moment().utc().add(5, "minutes").toISOString(),
    lastRun: moment().utc().subtract(55, "minutes").toISOString(),
    created: moment().utc().subtract(7, "days").toISOString(),
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: true,
    typeDisplayName: "Group Sync, Remove Members",
    triggerDisplayName: "Hourly",
    schedule: "HOURLY",
    config: exampleConfigs[1],
  },
  {
    id: uniqueId("rrrannndoomm-"),
    name: "Ready to run in the next 16+ mins.",
    description: "A big long description about this example",
    nextRun: moment().utc().add(20, "minutes").toISOString(),
    lastRun: moment().utc().subtract(55, "minutes").toISOString(),
    created: moment().utc().subtract(7, "days").toISOString(),
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: true,
    typeDisplayName: "Group Sync, Remove Members",
    triggerDisplayName: "Hourly",
    schedule: "HOURLY",
    config: exampleConfigs[1],
  },
  {
    id: uniqueId("rrrannndoomm-"),
    name: "Add long description, disabled",
    startDate: "2023-12-29T10:03:09.724618",
    description: "A big long description about this example ".repeat(10),
    lastRun: "2024-02-10T22:08:09.885338",
    created: "2023-12-29T10:03:09.724618",
    nextRun: "2024-02-10T23:05:41.179869",
    ownerAccountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
    enabled: false,
    typeDisplayName: "Group Sync, Add Members",
    triggerDisplayName: "Hourly",
    schedule: "HOURLY",
    config: exampleConfigs[0],
  },
];

const automationListItems: AutomationListItem[] = automations.map(
  (automation) => ({
    id: automation.id,
    name: automation.name,
    description: automation.description,
    lastRun: automation.lastRun,
    created: automation.created,
    schedule: automation.schedule,
    ownerAccountId: automation.ownerAccountId,
    enabled: automation.enabled,
    typeDisplayName: automation.typeDisplayName,
    triggerDisplayName: automation.triggerDisplayName,
  }),
);

export const getUserResponse = {
  self: "https://no-user-manages-users.atlassian.net/rest/api/3/user?accountId=557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
  accountId: "557058:4336ae99-e6d7-4f02-80fb-7b61adcefec6",
  accountType: "atlassian",
  avatarUrls: {
    "48x48":
      "https://secure.gravatar.com/avatar/82b0ccef8e2eb90ba9fd037778a193c0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTB-0.png",
    "24x24":
      "https://secure.gravatar.com/avatar/82b0ccef8e2eb90ba9fd037778a193c0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTB-0.png",
    "16x16":
      "https://secure.gravatar.com/avatar/82b0ccef8e2eb90ba9fd037778a193c0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTB-0.png",
    "32x32":
      "https://secure.gravatar.com/avatar/82b0ccef8e2eb90ba9fd037778a193c0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTB-0.png",
  },
  displayName: "Thomas Bradshaw",
  active: true,
  timeZone: "Australia/Sydney",
  locale: "en_US",
  groups: {
    size: 3,
    items: [],
  },
  applicationRoles: {
    size: 1,
    items: [],
  },
  expand: "groups,applicationRoles",
};

export const getAutomationsResponse: AutomationPageResponse = {
  data: automationListItems.sort((a, b) => a.name.localeCompare(b.name)),
  total: automationListItems.length,
};

export function updateMockAutomation(id: string, body: any) {
  const item = automations.find(
    (automation: Automation) => automation.id === id,
  );

  if (item === undefined) {
    throw Error(`No automation found with id ${id}`);
  }

  Object.assign(item, body);
}

export function getAutomationResponse(id: string): Automation {
  const item = automations.find(
    (automation: Automation) => automation.id === id,
  );

  if (item === undefined) {
    throw Error(`No automation found with id ${id}`);
  }

  return item;
}

export const getAutomationRunsResponse = {
  data: [
    {
      id: 1,
      date: "2024-02-10T21:08:09.885338",
      status: "OK",
      errorMessage: null,
    },
    {
      id: 2,
      date: "2024-02-10T22:08:09.885338",
      status: "OK",
      errorMessage: null,
    },
    {
      id: 3,
      date: "2024-02-10T23:08:09.885338",
      status: "Error",
      errorMessage: "Group not found",
    },
  ],
  total: 3,
};
