import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clearConfig, saveConfig } from ".";
import { SaveApiKeyRequest } from "../../types";

export const useCreateApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SaveApiKeyRequest>({
    mutationKey: ["config", "save"],
    mutationFn: (config) => saveConfig(config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["config"] });
    },
  });
};

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error>({
    mutationKey: ["config", "clear"],
    mutationFn: clearConfig,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["config"] });
    },
  });
};
