import React from "react";
import SectionMessage from "@atlaskit/section-message";

const ErrorSection: React.FC = () => {
  return (
    <SectionMessage appearance="error">
      <p>Oops! An error occurred.</p>
    </SectionMessage>
  );
};

export default ErrorSection;
