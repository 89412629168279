import React from "react";
import { Box, xcss } from "@atlaskit/primitives";

export const PageContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      xcss={xcss({
        paddingLeft: "space.400",
        paddingRight: "space.800",
      })}
    >
      {children}
    </Box>
  );
};
