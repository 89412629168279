import { ConfigurationStatus, SaveApiKeyRequest } from "../../types";
import axios from "axios";
import { getTokenHeaders } from "../JavascriptAPI";

export const getConfigStatus = async (): Promise<ConfigurationStatus> => {
  try {
    const token = await getTokenHeaders();
    const response = await axios.get("/api/config/status", {
      headers: {
        ...token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching config status:", error);
    throw error;
  }
};

export const clearConfig = async (): Promise<void> => {
  const token = await getTokenHeaders();
  const response = await fetch("/api/config", {
    method: "DELETE",
    headers: {
      ...token,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to save API key.");
  }
};

export const saveConfig = async (data: SaveApiKeyRequest): Promise<void> => {
  const token = await getTokenHeaders();
  const response = await fetch("/api/config", {
    method: "POST",
    headers: {
      ...token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to save API key.");
  }
};
