import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Inline, xcss } from "@atlaskit/primitives";
import AutomationsIcon from "@atlaskit/icon/glyph/list";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import { N40 } from "@atlaskit/theme/colors";
import { token } from "@atlaskit/tokens";

import {
  ButtonItem,
  Header,
  NavigationHeader,
  NestableNavigationContent,
  Section,
  SideNavigation,
} from "@atlaskit/side-navigation";
import { FlagsProvider } from "@atlaskit/flag";

export const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <SideNavigation label="project" testId="side-navigation">
      <NestableNavigationContent
        initialStack={[]}
        testId="nestable-navigation-content"
      >
        <Section>
          <NavigationHeader>
            <Header>
              <Inline alignBlock="center" space="space.100">
                <img
                  decoding="async"
                  sizes="16px"
                  srcSet="logo1024.png 744w, logo1024.png 1169w"
                  src="logo1024.png"
                  alt=""
                />
                <span>Admin Automations</span>
              </Inline>
            </Header>
          </NavigationHeader>
          <ButtonItem
            isSelected={
              location.pathname.includes("/automation") ||
              location.pathname === "/"
            }
            onClick={() => navigate("/automation")}
            iconBefore={<AutomationsIcon label="Automations" />}
          >
            Automations
          </ButtonItem>
          <ButtonItem
            isSelected={location.pathname === "/configuration"}
            onClick={() => navigate("/configuration")}
            iconBefore={<SettingsIcon label="Configuration" />}
          >
            Configuration
          </ButtonItem>
        </Section>
      </NestableNavigationContent>
      <Inline alignInline="center">
        <Box
          as="small"
          padding="space.100"
          xcss={xcss({
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "14em",
          })}
        >
          {process.env.REACT_APP_VERSION}
        </Box>
      </Inline>
    </SideNavigation>
  );
};

export const SideNavLayout = () => {
  const leftNavCss = xcss({
    display: "flex",
    flexDirection: "column",
    borderRight: `1px solid ${token("color.border", N40)}`,
    maxWidth: "304px",
  });
  const rightContentCss = xcss({
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  });
  const containerCss = xcss({
    display: "flex",
    height: "100vh",
  });

  return (
    <Box xcss={containerCss}>
      <Box xcss={leftNavCss}>
        <SideNav></SideNav>
      </Box>
      <Box xcss={rightContentCss}>
        <FlagsProvider>
          <Outlet />
        </FlagsProvider>
      </Box>
    </Box>
  );
};
