import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { DateTimePicker } from "@atlaskit/datetime-picker";
import { ErrorMessage, Field, HelperMessage } from "@atlaskit/form";
import { Meta } from "@atlaskit/form/dist/types/field";

interface ScheduledDateTimePickerProps {
  fieldName: string;
  automation?: { nextRun?: string };
  required?: boolean;
  helperMessage?: string;
  label?: string;
}

export const validateDate = (value?: string, required: boolean = false) => {
  if (!value && required) {
    return "REQUIRED";
  } else if (value && new Date(value) < new Date()) {
    return "EXPIRED";
  }
};
const Picker: React.FC<{
  fieldProps: any;
  error?: string;
  valid: boolean;
  meta: Meta;
  helperMessage?: string;
}> = ({ fieldProps, error, helperMessage }) => {
  const value = fieldProps.value;
  const [times, setTimes] = useState<string[]>(
    _.range(0, 24).flatMap((hour) => [
      ("0" + hour).slice(-2) + ":00",
      ("0" + hour).slice(-2) + ":30",
    ]),
  );

  useEffect(() => {
    var currentTime: string;
    if (
      value &&
      moment(value).local().format("YYYY-MM-DD") ===
        moment().local().format("YYYY-MM-DD")
    ) {
      const currentMoment = moment().local();
      const currentHour = currentMoment.hour();
      currentTime =
        currentMoment.minutes() < 30
          ? ("0" + currentHour).slice(-2) + ":30"
          : ("0" + currentHour + 1).slice(-2) + ":00";
    }
    setTimes(
      _.range(0, 24)
        .flatMap((hour) => [
          ("0" + hour).slice(-2) + ":00",
          ("0" + hour).slice(-2) + ":30",
        ])
        .filter((time) => {
          return currentTime ? time >= currentTime : true;
        }),
    );
  }, [value]);
  return (
    <>
      <DateTimePicker
        datePickerProps={{
          dateFormat: "YYYY-MM-DD",
          placeholder: moment().local().format("YYYY-MM-DD"),
          minDate: moment().local().format("YYYY-MM-DD"),
          maxDate: moment().add(180, "days").local().format("YYYY-MM-DD"),
        }}
        timePickerProps={{
          placeholder: "now",
          times: times,
        }}
        dateFormat="YYYY-MM-DD"
        timeFormat="HH:mm"
        {...fieldProps}
        datePickerSelectProps={{ inputId: fieldProps.id }}
      />
      {helperMessage && <HelperMessage>{helperMessage}</HelperMessage>}
      {error === "REQUIRED" && (
        <ErrorMessage>This field is required</ErrorMessage>
      )}
      {error === "EXPIRED" && (
        <ErrorMessage>
          You can only schedule the rule for a future date
        </ErrorMessage>
      )}
    </>
  );
};

const ScheduledDateTimePicker: React.FC<ScheduledDateTimePickerProps> = ({
  fieldName,
  automation,
  required,
  helperMessage,
  label,
}) => {
  const nextRun = automation?.nextRun
    ? moment(automation.nextRun).local().format("YYYY-MM-DD HH:mm")
    : undefined;
  return (
    <>
      <Field
        name={fieldName}
        label={label}
        isRequired={required}
        defaultValue={nextRun}
        validate={(value) => validateDate(value, required)}
      >
        {({ ...props }) => {
          return (
            <>
              <Picker {...props} helperMessage={helperMessage} />
            </>
          );
        }}
      </Field>
    </>
  );
};

export default ScheduledDateTimePicker;
