import { Automation, AutomationListItem } from "../../types/Automation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAutomationRequest,
  RescheduleAutomationRequest,
  UpdateAutomationRequest,
  createAutomation,
  deleteAutomation,
  deleteAutomationSchedule,
  scheduleAutomation,
  updateAutomation,
} from ".";

export const useCreateAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<AutomationListItem, Error, CreateAutomationRequest>({
    mutationFn: (config) => createAutomation(config),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["automations"],
        exact: false,
      });
    },
  });
};

export const useDeleteAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: deleteAutomation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["automations"],
        exact: false,
      });
    },
  });
};

export const useUpdateAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    Automation,
    Error,
    {
      automationId: string;
      updatedAutomation: UpdateAutomationRequest;
    }
  >({
    mutationFn: ({ automationId, updatedAutomation }) =>
      updateAutomation(automationId, updatedAutomation),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ["automations"],
        exact: false,
      });
    },
  });
};

export const useDeleteScheduleAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: async (automationId) => {
      await deleteAutomationSchedule(automationId);
    },
    onSuccess: (data, automationId) => {
      queryClient.invalidateQueries({
        queryKey: ["automations"],
        exact: false,
      });
    },
  });
};

export const useScheduleAutomation = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, RescheduleAutomationRequest>({
    mutationFn: async (request) => {
      await scheduleAutomation(request);
    },
    onSuccess: (data, { automationId }) => {
      queryClient.invalidateQueries({
        queryKey: ["automations"],
      });
    },
  });
};
