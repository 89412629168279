import React from "react";
import "./divider.css";
import { Box } from "@atlaskit/primitives";

const Divider: React.FC = () => {
  return (
    <Box padding="space.200">
      <div className="section-divider--horizontal"></div>
    </Box>
  );
};

export default Divider;
