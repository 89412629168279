import React from "react";
import { AsyncSelect, OptionType } from "@atlaskit/select";
import { getGroups } from "../../clients/jira";

const promiseOptions = async (inputValue: string) => {
  try {
    const groupResponse = await getGroups(inputValue);
    const options: OptionType[] = [];
    groupResponse.groups.forEach((group) => {
      options.push({ label: group.name, value: group.groupId });
    });
    return options;
  } catch (error) {
    console.error(error);
  }
};

interface Props {
  rest: any;
  isMulti?: boolean;
}

const GroupSelect: React.FC<Props> = ({ isMulti, rest }) => {
  return (
    <>
      <AsyncSelect
        inputId={rest.id}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        isMulti={isMulti ? true : false}
        {...rest}
      />
    </>
  );
};

export default GroupSelect;
