import axios from "axios";
import {
  Automation,
  AutomationConfig,
  AutomationListItem,
  AutomationPageResponse,
  AutomationSchedule,
  ExecutionPageResponse,
} from "../../types/Automation";
import { getTokenHeaders } from "../JavascriptAPI";

export const getAutomations = async (
  page: number = 0,
  limit: number = 10,
): Promise<AutomationPageResponse> => {
  try {
    const token = await getTokenHeaders();
    const response = await axios.get<AutomationPageResponse>(
      "/api/automations",
      {
        params: {
          page,
          limit,
        },
        headers: {
          ...token,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching automations:", error);
    throw error;
  }
};

export const getExecutions = async (
  id: string,
  page: number = 0,
  limit: number = 10,
): Promise<ExecutionPageResponse> => {
  try {
    const token = await getTokenHeaders();
    const response = await axios.get<ExecutionPageResponse>(
      `/api/automations/${id}/executions`,
      {
        params: {
          page,
          limit,
        },
        headers: {
          ...token,
        },
      },
    );
    const data = {
      ...response.data,
      data: response.data.data.map((d) => ({
        ...d,
        timeStarted: new Date(d.timeStarted),
        timeFinished: new Date(d.timeFinished),
      })),
    };
    return data;
  } catch (error) {
    console.error("Error fetching executions:", error);
    throw error;
  }
};

export const getAutomation = async (
  id: string | number,
): Promise<Automation> => {
  try {
    const token = await getTokenHeaders();
    const response = await axios.get<Automation>(`/api/automations/${id}`, {
      headers: {
        ...token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching automation:", error);
    throw error;
  }
};

export const deleteAutomation = async (id: string): Promise<void> => {
  try {
    const token = await getTokenHeaders();
    await axios.delete<string>(`/api/automations/${id}`, {
      headers: {
        ...token,
      },
    });
  } catch (error) {
    console.error("Error deleting automation:", error);
    throw error;
  }
};

export interface RescheduleAutomationRequest {
  automationId: string;
  schedule: AutomationSchedule;
  startDate?: string;
}

export const scheduleAutomation = async ({
  automationId,
  ...rest
}: RescheduleAutomationRequest): Promise<void> => {
  try {
    const token = await getTokenHeaders();
    await axios.put<string>(`/api/automations/${automationId}/schedule`, rest, {
      headers: {
        ...token,
      },
    });
  } catch (error) {
    console.error("Error scheduling automation:", error);
    throw error;
  }
};

export const deleteAutomationSchedule = async (
  automationId: string,
): Promise<void> => {
  try {
    const token = await getTokenHeaders();
    await axios.delete<string>(`/api/automations/${automationId}/schedule`, {
      headers: {
        ...token,
      },
    });
  } catch (error) {
    console.error("Error cancelling automation schedule", error);
    throw error;
  }
};

export interface CreateAutomationRequest {
  name: string;
  description: string;
  config: AutomationConfig;
  schedule: string;
  startDate: string;
}

export const createAutomation = async (
  postData: CreateAutomationRequest,
): Promise<AutomationListItem> => {
  const token = await getTokenHeaders();
  try {
    const response = await axios.post("/api/automations", postData, {
      headers: {
        ...token,
      },
    });
    return response.data as AutomationListItem;
  } catch (error) {
    console.error("Error creating automation:", error);
    throw error;
  }
};

export interface UpdateAutomationRequest {
  name?: string;
  description?: string;
}

export const updateAutomation = async (
  automationId: string | number,
  postData: UpdateAutomationRequest,
): Promise<Automation> => {
  const token = await getTokenHeaders();
  try {
    const response = await axios.put(
      "/api/automations/" + automationId,
      postData,
      {
        headers: {
          ...token,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
