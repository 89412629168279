import React from "react";
import { LoadingButton as Button } from "@atlaskit/button";
import {
  Automation,
  AutomationListItem,
  AutomationSchedule,
} from "../../../types";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Form from "@atlaskit/form";
import moment from "moment";
import ScheduleSelect from "./schedule-select";
import ScheduledDateTimePicker, { validateDate } from "./datetime-select";

export const RescheduleModal = ({
  isOpen,
  closeModal,
  automation,
  onSubmit,
}: {
  isOpen: boolean;
  closeModal: () => void;
  automation: Automation | AutomationListItem;
  onSubmit: (startDate: string, newSchedule: AutomationSchedule) => void;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal} shouldScrollInViewport={true} height={400}>
          <Form
            onSubmit={async (data: {
              startDate: string;
              schedule: { value: AutomationSchedule };
            }) => {
              const dateInvalid = validateDate(data.startDate);
              if (!data.schedule?.value) {
                return {
                  schedule: "REQUIRED",
                  startDate: dateInvalid,
                };
              }
              if (dateInvalid) {
                return { startDate: dateInvalid };
              }
              await onSubmit(
                data.startDate && moment(data.startDate).utc().format(),
                data.schedule.value,
              );
              closeModal();
            }}
          >
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Reschedule rule?</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  This rule will be rescheduled to the new start date and be
                  triggered based on the new schedule.
                  <ScheduleSelect defaultValue={automation.schedule} />
                  <ScheduledDateTimePicker
                    fieldName="startDate"
                    helperMessage="Leave empty to start immediately"
                    label="Rescheduled start date"
                    automation={automation}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button appearance="subtle" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary">
                    Submit
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
};
