import { useQuery } from "@tanstack/react-query";
import { User, getUser, getGroup, GroupNameResponse } from ".";

export const useUserQuery = (id: string | undefined) => {
  return useQuery<unknown, Error, User, string[]>({
    queryKey: ["users", `${id}`],
    queryFn: ({ queryKey: [_key, id] }) => getUser(id),
    enabled: !!id,
    staleTime: Infinity,
  });
};

export const useGroupQuery = (id: string | undefined) => {
  return useQuery<unknown, Error, GroupNameResponse, string[]>({
    queryKey: ["groups", `${id}`],
    queryFn: ({ queryKey: [_key, id] }) => getGroup(id),
    enabled: !!id,
    staleTime: Infinity,
  });
};
