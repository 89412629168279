import { jsApiRequest } from "../JavascriptAPI";

export interface Group {
  name: string;
  html: string;
  groupId: string;
}

export interface GroupNameResponse {
  name?: string;
  found: boolean;
}

export interface GroupResponse {
  header: string;
  total: number;
  groups: Group[];
}

export interface BulkGroupsResponse {
  isLast: boolean;
  maxResults: number;
  startAt: number;
  total: number;
  values: Group[];
}

export const getGroups = async (query: string): Promise<GroupResponse> => {
  return await jsApiRequest({
    url: `/rest/api/3/groups/picker?query=${query}&maxResults=10`,
    type: "GET",
  });
};

export const getGroup = async (id: string): Promise<GroupNameResponse> => {
  const data = await jsApiRequest({
    url: `/rest/api/3/group/bulk?groupId=${id}&maxResults=1`,
    type: "GET",
  });

  if (data.values.length === 0) {
    return { found: false };
  }
  return { ...data.values[0], found: true };
};

export interface User {
  displayName: string;
  name: string;
  emailAddress: string;
  avatarUrls: any;
}

export const getUser = async (atlassianAccountId: string): Promise<User> => {
  return await jsApiRequest({
    url: `/rest/api/3/user?accountId=${atlassianAccountId}`,
    type: "GET",
  });
};
