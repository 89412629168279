import React from "react";
import { AutomationConfig } from "../../../../types/Automation";
import { GroupName } from "../group-name";
import { Box, Stack, xcss } from "@atlaskit/primitives";

function Label({ children }: { children: React.ReactNode }) {
  return (
    <Box
      xcss={xcss({
        color: "color.text.subtle",
        fontWeight: "600",
        minWidth: "8em",
      })}
    >
      {children}
    </Box>
  );
}

interface ViewAutomationConfigProps {
  config: AutomationConfig;
}

function renderConfig(config: AutomationConfig) {
  switch (config.type) {
    case "SYNC_MEMBERS_ADD":
      return (
        <Stack space="space.150">
          <Label>If a user is added to this group</Label>
          <Box>
            <GroupName groupId={config.sourceGroupId} />
          </Box>
          <Label>Then add the user to this group</Label>
          <Box>
            <GroupName groupId={config.targetGroupId} />
          </Box>
        </Stack>
      );
    case "SYNC_MEMBERS_REMOVE":
      return (
        <Stack space="space.150">
          <Label>If a user is removed from this group</Label>
          <Box>
            <GroupName groupId={config.sourceGroupId} />
          </Box>
          <Label>Then remove the user from this group</Label>
          <Box>
            <GroupName groupId={config.targetGroupId} />
          </Box>
        </Stack>
      );
    default:
      return null;
  }
}

const ViewAutomationConfig: React.FC<ViewAutomationConfigProps> = ({
  config,
}) => {
  return <>{renderConfig(config)}</>;
};

export default ViewAutomationConfig;
