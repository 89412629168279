import { ActionsType, useFlags } from "@atlaskit/flag";
import Tick from "@atlaskit/icon/glyph/check-circle";
import Error from "@atlaskit/icon/glyph/error";
import Info from "@atlaskit/icon/glyph/info";
import Warning from "@atlaskit/icon/glyph/warning";
import { token } from "@atlaskit/tokens";
import { useRef } from "react";

type FlagType = "success" | "info" | "warning" | "error";

function useSmolFlags() {
  const id = useRef<number>(1);
  const { showFlag } = useFlags();

  const addFlag = (
    title: string,
    description: string,
    flagType: FlagType,
    actions: ActionsType = [],
    isAutoDismiss: boolean = true,
  ) => {
    var icon = (
      <Info label="Info" secondaryColor={token("color.text.success")} />
    );
    switch (flagType) {
      case "success":
        icon = (
          <Tick label="Success" secondaryColor={token("color.text.success")} />
        );
        break;
      case "warning":
        icon = (
          <Warning
            label="Warning"
            secondaryColor={token("color.text.success")}
          />
        );
        break;
      case "error":
        icon = (
          <Error label="Error" secondaryColor={token("color.text.success")} />
        );
        break;
    }

    showFlag({
      actions,
      description,
      icon,
      id: id.current++,
      title,
      isAutoDismiss,
      appearance: flagType,
    });
  };
  return [addFlag];
}

export default useSmolFlags;
