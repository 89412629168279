import {
  Automation,
  AutomationPageResponse,
  ExecutionPageResponse,
} from "../../types/Automation";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { getAutomation, getAutomations, getExecutions } from ".";

export const useAutomationsQuery = (page: number, limit: number) => {
  return useQuery<unknown, Error, AutomationPageResponse, any[]>({
    queryKey: ["automations", page, limit],
    queryFn: ({ queryKey: [_key, page, limit] }) => getAutomations(page, limit),
    placeholderData: keepPreviousData,
    staleTime: 60000,
  });
};

export const useAutomationQuery = (id: string | undefined) => {
  return useQuery<unknown, Error, Automation, string[]>({
    queryKey: ["automations", `${id}`],
    queryFn: ({ queryKey: [_key, id] }) => getAutomation(id),
    enabled: !!id,
    staleTime: 15000,
  });
};

export const useAutomationExecutionsQuery = (
  id: string | undefined,
  page: number = 0,
  limit: number = 5,
) => {
  return useQuery<unknown, Error, ExecutionPageResponse, string[]>({
    queryKey: ["automations", `${id}`, "executions", `${page}`, `${limit}`],
    queryFn: ({ queryKey: [_key, id] }) => getExecutions(id, page, limit),
    enabled: !!id,
    staleTime: 10000,
  });
};
