import { ButtonGroup, LoadingButton as Button } from "@atlaskit/button";
import { Automation } from "../../../types";
import { AutomationListItem, AutomationSchedule } from "../../../types";
import {
  useDeleteScheduleAutomation,
  useScheduleAutomation,
} from "../../../clients/automations/automation-mutations";
import useSmolFlags from "../../Layouts/flag-helper";
import AutomationMenu from "../list/automation-menu";

const AutomationActions = ({
  automation,
}: {
  automation: Automation | AutomationListItem;
}) => {
  const [addFlag] = useSmolFlags();
  const { mutate: rescheduleMutation, isPending: isReschedulePending } =
    useScheduleAutomation();

  const { mutate: deleteScheduleMutation, isPending: isDisablePending } =
    useDeleteScheduleAutomation();

  async function scheduleAutomation(
    automation: AutomationListItem | Automation,
    schedule?: AutomationSchedule,
    startDate?: string,
  ) {
    return rescheduleMutation(
      {
        automationId: automation!.id,
        schedule: schedule || automation!.schedule,
        startDate: startDate || automation!.startDate,
      },
      {
        onSuccess: () => {
          addFlag(
            "Automation scheduled",
            "Automation was scheduled successfully",
            "success",
          );
        },
        onError: () => {
          addFlag(
            "Error scheduling automation",
            "Something went wrong, try again later or contact support.",
            "warning",
          );
        },
      },
    );
  }

  function deleteAutomationSchedule(automationId: string) {
    deleteScheduleMutation(`${automationId}`, {
      onSuccess: () => {
        addFlag(
          "Automation disabled",
          "Automation was updated successfully",
          "success",
        );
      },
      onError: () => {
        addFlag(
          "Error disabling automation",
          "Something went wrong, it may have been running, try again later or contact support.",
          "warning",
        );
      },
    });
  }

  const lockActions =
    isReschedulePending || isDisablePending || automation.running;

  return (
    <>
      <ButtonGroup label="Automation actions">
        {automation.enabled ? (
          <>
            <Button
              isLoading={isDisablePending}
              isDisabled={lockActions}
              appearance="default"
              onClick={() => deleteAutomationSchedule(automation.id)}
            >
              Disable
            </Button>
          </>
        ) : (
          <>
            <Button
              isLoading={isReschedulePending}
              isDisabled={lockActions}
              appearance="primary"
              onClick={() => scheduleAutomation(automation)}
            >
              Run Now
            </Button>
          </>
        )}
        <AutomationMenu
          automation={automation}
          showView={false}
          hideEnableDisable
        />
      </ButtonGroup>
    </>
  );
};

export default AutomationActions;
