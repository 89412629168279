import React from "react";
import { useParams } from "react-router-dom";
import Profile from "../../../components/Profile/Profile";
import moment from "moment";
import { Box, Inline, Stack, xcss } from "@atlaskit/primitives";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import _ from "lodash";
import PageHeader from "@atlaskit/page-header";
import Lozenge from "@atlaskit/lozenge";
import { PageContent } from "../../../components/PageContent";
import ViewAutomationConfig from "../components/automation-configs/view-automation-config";
import { useAutomationQuery } from "../../../clients/automations/automation-queries";
import LoadingTracker from "../../../components/LoadingTracker/LoadingTracker";
import { PropertyDisplay } from "../components/property-display";
import ErrorSection from "../../../components/ErrorSection";
import { nextRun } from "../nextRun";
import AutomationActions from "../components/automation-actions";
import ExecutionsTable from "./executions-table";

export interface AutomationProps {}

const DATE_FORMAT = "LLL";

export const AutomationView: React.FC<AutomationProps> = () => {
  let { id } = useParams();
  const {
    data: automation,
    isLoading: loading,
    isError,
  } = useAutomationQuery(id);

  const breadcrumbs = (
    <Breadcrumbs onExpand={_.noop}>
      <BreadcrumbsItem text="Rules" key="Rules" />
      <BreadcrumbsItem text="Details" key={`${id}`} />
    </Breadcrumbs>
  );

  return (
    <>
      {isError && <ErrorSection />}
      {loading && <LoadingTracker />}
      {automation && (
        <PageContent>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={
              <>
                <AutomationActions automation={automation} />
              </>
            }
          >
            {automation.name}
          </PageHeader>
          <Box
            xcss={xcss({
              paddingTop: "space.050",
              paddingBottom: "space.250",
            })}
          >
            {automation.description}
          </Box>
          <Inline grow="fill" rowSpace="space.100" spread="space-between">
            <Stack grow="fill">
              <Box
                xcss={xcss({
                  paddingBottom: "space.200",
                  marginBottom: "space.025",
                })}
              >
                <Stack space="space.200">
                  <PropertyDisplay
                    label="Rule status"
                    value={
                      <Lozenge
                        appearance={automation.enabled ? "success" : "removed"}
                      >
                        {automation.enabled ? "Enabled" : "Disabled"}
                      </Lozenge>
                    }
                  />
                  {automation.enabled && (
                    <PropertyDisplay
                      label="Next run"
                      value={nextRun(automation)}
                    />
                  )}
                  <PropertyDisplay
                    label="Rule type"
                    value={automation.typeDisplayName}
                  />
                  <PropertyDisplay
                    label="Frequency"
                    value={automation.triggerDisplayName}
                  />
                  <PropertyDisplay
                    label="Last run date"
                    value={
                      automation.lastRun
                        ? moment(automation.lastRun).local().format(DATE_FORMAT)
                        : "Never"
                    }
                  />
                  <PropertyDisplay
                    label="Created on"
                    value={moment(automation.created)
                      .local()
                      .format(DATE_FORMAT)}
                  />
                  <PropertyDisplay
                    label="Created by"
                    value={
                      <Profile
                        atlassianAccountId={automation.ownerAccountId}
                        showAvatar={false}
                        showName
                        size="small"
                      />
                    }
                  />
                </Stack>
              </Box>
              <Box
                xcss={xcss({
                  paddingTop: "space.200",
                  borderColor: "color.border",
                  borderTopWidth: "border.width.outline",
                  borderTopStyle: "solid",
                  width: "20em",
                  marginLeft: "1em",
                })}
              />
              <ViewAutomationConfig config={automation.config} />
            </Stack>
            <Stack grow="fill">
              <Box
                xcss={xcss({
                  borderColor: "color.border",
                  borderBottomWidth: "border.width.outline",
                  borderBottomStyle: "solid",
                  fontWeight: "600",
                  color: "color.text.subtle",
                })}
              >
                Last 5 rule executions
              </Box>
              <Box xcss={xcss({ paddingTop: "space.025" })}>
                {id && <ExecutionsTable automationId={id} />}
              </Box>
            </Stack>
          </Inline>
        </PageContent>
      )}
    </>
  );
};

export default AutomationView;
