import React from "react";
import Spinner from "@atlaskit/spinner";
import "./LoadingTracker.css";

const LoadingTracker: React.FC = () => {
  return (
    <div className="loading-spinner">
      <Spinner size="large" />
    </div>
  );
};

export default LoadingTracker;
