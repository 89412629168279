import React from "react";
import AddGroupsConfig from "./automation-configs/groups-add";
import RemoveGroupsConfig from "./automation-configs/groups-remove";
import { AutomationTypes } from "../../../types";
import { Field, ErrorMessage } from "@atlaskit/form";
import Select, { OptionType, ValueType } from "@atlaskit/select";

export type AutomationOptionType = {
  value: AutomationTypes;
  label: string;
  description: string;
  component: React.ReactNode;
  transformConfig: (config: any) => any;
  validateConfig: (data: any) => any;
};

type AutomationSelectProps = {};

const sourceAndTargetGroupValidation = (data: any) => {
  var validationErrors: any;
  if (!data.config.sourceGroupId.value) {
    validationErrors = { sourceGroupId: "This group selection is required" };
  }
  if (!data.config.targetGroupId.value) {
    validationErrors = {
      targetGroupId: "This group selection is required",
      ...validationErrors,
    };
  }
  if (data.config.sourceGroupId.value === data.config.targetGroupId.value) {
    validationErrors = {
      sourceGroupId: "Source and target groups cannot be the same",
      ...validationErrors,
    };
  }
  return validationErrors && { config: { ...validationErrors } };
};

const automationTypes: AutomationOptionType[] = [
  {
    value: "SYNC_MEMBERS_ADD",
    label: "Add user to group",
    description: "Add a user to a group",
    component: (
      <div>
        <AddGroupsConfig name={""} />
      </div>
    ),
    transformConfig: (config: any) => {
      return {
        sourceGroupId: config?.sourceGroupId?.value,
        targetGroupId: config?.targetGroupId?.value,
      };
    },
    validateConfig: sourceAndTargetGroupValidation,
  },
  {
    value: "SYNC_MEMBERS_REMOVE",
    label: "Remove user from group",
    description: "Remove a user from a group",
    component: (
      <div>
        <RemoveGroupsConfig name={""} />
      </div>
    ),
    transformConfig: (config: any) => {
      return {
        sourceGroupId: config?.sourceGroupId?.value,
        targetGroupId: config?.targetGroupId?.value,
      };
    },
    validateConfig: sourceAndTargetGroupValidation,
  },
];

export const getAutomationTypeByKey = (
  key: string | number | undefined,
): AutomationOptionType | undefined =>
  automationTypes.filter((type) => type.value === key)[0] || null;

const AutomationSelect: React.FC<AutomationSelectProps> = () => {
  const options = automationTypes.map((type) => ({
    value: type.value,
    label: type.label,
  }));

  return (
    <Field<ValueType<OptionType, false>>
      name="config.type"
      label="Rule type"
      isRequired
      validate={(value) => {
        return value && getAutomationTypeByKey(value.value)
          ? undefined
          : "Rule type is required";
      }}
    >
      {({ fieldProps, error }) => {
        return (
          <>
            <Select options={options} {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        );
      }}
    </Field>
  );
};

export default AutomationSelect;
