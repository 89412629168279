import React from "react";
import { Box, Inline, xcss } from "@atlaskit/primitives";

interface PropertyDisplayProps {
  label: string;
  value: any;
}
export const PropertyDisplay: React.FC<PropertyDisplayProps> = ({
  label,
  value,
}) => {
  return (
    <Inline space="space.100">
      <Box
        xcss={xcss({
          color: "color.text.subtle",
          fontWeight: "600",
          minWidth: "8em",
        })}
      >
        {label}
      </Box>
      <Box>{value}</Box>
    </Inline>
  );
};
