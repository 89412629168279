import Button from "@atlaskit/button";
import Form, {
  ErrorMessage,
  Field,
  FormHeader,
  FormSection,
  useFormState,
} from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CreateAutomationRequest, createAutomation } from "../../../clients";
import { CenteredContainer } from "../../../components/CenteredContainer";
import AutomationSelect, {
  AutomationOptionType,
  getAutomationTypeByKey,
} from "../components/automation-select";
import { Box, Inline, Stack, xcss } from "@atlaskit/primitives";
import moment from "moment";
import ScheduleSelect from "../components/schedule-select";
import Divider from "../components/divider";
import Banner from "@atlaskit/banner";
import PageHeader from "@atlaskit/page-header";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import _ from "lodash";
import { PageContent } from "../../../components/PageContent";
import ScheduledDateTimePicker from "../components/datetime-select";

export const ConfigureAutomationSection: React.FC<{}> = () => {
  const formState = useFormState<any>({ values: true });
  const selectedAutomation: AutomationOptionType | undefined =
    getAutomationTypeByKey(formState?.values?.config?.type?.value);
  return (
    <>
      {!selectedAutomation ? (
        <Banner appearance="announcement">
          Please select a rule type to continue.{" "}
        </Banner>
      ) : (
        <></>
      )}
      {selectedAutomation?.component}
    </>
  );
};

export interface AutomationProps {}

export const CreateAutomation: React.FC<AutomationProps> = () => {
  const navigate = useNavigate();
  const handleSubmit = async (data: any) => {
    var validationErrors: any;
    const selectedAutomation = getAutomationTypeByKey(
      data?.config?.type?.value,
    );
    if (data.config?.type) {
      const validatedConfigErrors = selectedAutomation?.validateConfig(data);
      if (validatedConfigErrors)
        validationErrors = {
          ...validatedConfigErrors,
          ...validationErrors,
        };
    } else {
      validationErrors = {
        selectedAutomation: "REQUIRED",
        ...validationErrors,
      };
    }

    if (!data.schedule?.value) {
      validationErrors = {
        schedule: "REQUIRED",
        ...validationErrors,
      };
    }

    if (validationErrors) {
      console.log("Validation errors", validationErrors);
      return validationErrors;
    }

    const postData: CreateAutomationRequest = {
      name: data.name,
      description: data.description,
      config: {
        type: data?.config?.type?.value,
        ...selectedAutomation?.transformConfig(data.config),
      },
      schedule: data.schedule.value,
      startDate: data.startDate && moment(data.startDate).utc().format(),
    };

    const response = await createAutomation(postData);
    navigate(`/automation/${response.id}`);
  };

  return (
    <PageContent>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs onExpand={_.noop}>
            <BreadcrumbsItem
              text="Rules"
              key="Rules"
              onClick={() => {
                navigate("/");
              }}
            />
            <BreadcrumbsItem text="Create" key="Create" />
          </Breadcrumbs>
        }
      ></PageHeader>
      <CenteredContainer maxWidth={"540px"}>
        <Form onSubmit={handleSubmit}>
          {({ formProps }) => {
            return (
              <form {...formProps}>
                <Stack space="space.200">
                  <FormHeader title="Add new rule">
                    <p aria-hidden="true">
                      Choose your rule type, then enter the rule name, schedule
                      and select the groups you want to use.
                    </p>
                  </FormHeader>

                  <AutomationSelect />

                  <Divider />

                  <Field
                    name="name"
                    validate={(value) =>
                      value
                        ? value.length < 255
                          ? undefined
                          : "Rule name is too long"
                        : "Rule name is required"
                    }
                    defaultValue=""
                    label="Rule name"
                    isRequired
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField
                          placeholder="Enter the rules name"
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <Field
                    name="description"
                    defaultValue=""
                    label="Rule description"
                    validate={(value) =>
                      !value || value.length < 1000
                        ? undefined
                        : "Rule description is too long"
                    }
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextArea
                          placeholder="Enter the rules description"
                          resize="auto"
                          maxHeight="20vh"
                          defaultValue=""
                          onPointerEnterCapture={_.noop}
                          onPointerLeaveCapture={_.noop}
                          {...fieldProps}
                          onChange={(event) => {
                            const value = event.currentTarget.value;
                            fieldProps.onChange(value);
                          }}
                        ></TextArea>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  <FormSection
                    title="Schedule"
                    description="Rules are scheduled to run when you want; Hourly, daily, weekly or monthly."
                  >
                    <Box
                      xcss={xcss({
                        paddingBlock: "space.100",
                      })}
                    >
                      <ScheduledDateTimePicker
                        fieldName="startDate"
                        label="Start date"
                        helperMessage="Leave empty to schedule immediately."
                      />
                    </Box>
                    <ScheduleSelect />
                  </FormSection>

                  <Divider />

                  <ConfigureAutomationSection></ConfigureAutomationSection>

                  <Inline space="space.100" alignInline="end">
                    <Button appearance="subtle" onClick={() => navigate("/")}>
                      Cancel
                    </Button>
                    <Button type="submit" appearance="primary">
                      Submit
                    </Button>
                  </Inline>
                </Stack>
              </form>
            );
          }}
        </Form>
      </CenteredContainer>
    </PageContent>
  );
};

export default CreateAutomation;
