import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./pages/error-page";
import { SideNavLayout } from "./pages/Layouts/site-nav-layout";
import { Configuration, AutomationsList } from "./pages";
import CreateAutomation from "./pages/Automation/create/automation-create";
import { AutomationView } from "./pages/Automation/view/automation-view";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getConfigStatus } from "./clients";
import { worker } from "./_mocks/browser";

if (process.env.NODE_ENV === "development") {
  worker.start();
}

const apiConfigLoader = async () => {
  const data = await getConfigStatus();
  if (!data?.apiKeyProvided || data?.status === "UNAUTHORIZED") {
    return redirect("/configuration");
  }
  return data;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <SideNavLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        loader: apiConfigLoader,
        path: "",
        element: <AutomationsList />,
      },
      {
        loader: apiConfigLoader,
        path: "automation",
        element: <AutomationsList />,
      },
      {
        loader: apiConfigLoader,
        path: "automation/create",
        element: <CreateAutomation />,
      },
      {
        loader: apiConfigLoader,
        path: "automation/:id",
        element: <AutomationView />,
      },
      {
        path: "configuration",
        element: <Configuration />,
      },
    ],
  },
]);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
