import React from "react";
import RulesTable from "./AutomationsTable/AutomationsTable";
import { useNavigate } from "react-router-dom";
import { Box, Inline } from "@atlaskit/primitives";
import Button from "@atlaskit/button";
import PageHeader from "@atlaskit/page-header";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import _ from "lodash";
import { PageContent } from "../../../components/PageContent";

export const AutomationsList: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageContent>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs onExpand={_.noop}>
            <BreadcrumbsItem
              text="Rules"
              key="Rules"
              onClick={() => {
                navigate("/");
              }}
            />
          </Breadcrumbs>
        }
        actions={
          <Inline>
            <Button
              appearance="primary"
              onClick={() => navigate("/automation/create")}
            >
              Add Rule
            </Button>
          </Inline>
        }
      >
        Automation List
      </PageHeader>
      <Box padding="space.100">
        <RulesTable />
      </Box>
    </PageContent>
  );
};
