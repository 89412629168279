import React, { Fragment } from "react";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import Button, { ButtonGroup } from "@atlaskit/button";
import Form, { Field } from "@atlaskit/form";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import PageHeader from "@atlaskit/page-header";
import TextField from "@atlaskit/textfield";
import _ from "lodash";
import moment from "moment";
import CustomSectionMessage from "../../components/CustomSectionMessage/CustomSectionMessage";
import { PageContent } from "../../components/PageContent";
import Profile from "../../components/Profile/Profile";
import CenteredContainer from "../../components/CenteredContainer";
import { useConfigStatusQuery } from "../../clients/config/config-queries";
import LoadingTracker from "../../components/LoadingTracker/LoadingTracker";
import {
  useCreateApiKey,
  useDeleteApiKey,
} from "../../clients/config/config-mutations";
import useSmolFlags from "../Layouts/flag-helper";
import { Box, Inline, Stack, xcss } from "@atlaskit/primitives";
import { ClearApiAction } from "./configuration-clear";
import ErrorSection from "../../components/ErrorSection";

export const Configuration: React.FC = () => {
  const {
    data: configStatus,
    isFetching: isLoading,
    isError,
    refetch,
  } = useConfigStatusQuery();
  const { mutate: saveApiKey, isPending: createPending } = useCreateApiKey();
  const { mutate: clearApiKey, isPending: deletePending } = useDeleteApiKey();
  const [addFlag] = useSmolFlags();
  const mutationPending = createPending || deletePending;

  const handleSaveApiKey = async (formState: { apiKey: string }) => {
    await saveApiKey(formState, {
      onSuccess: () => {
        addFlag(
          "API key saved",
          "API key has been saved successfully",
          "success",
        );
      },
      onError: () => {
        addFlag(
          "Failed to save the API key",
          "Failed to save the API key, it may not be valid.",
          "error",
        );
      },
    });

    await refetch();
  };

  const handleClearApiKey = async () => {
    await clearApiKey(undefined, {
      onSuccess: () => {
        addFlag(
          "API key cleared",
          "API key has been cleared successfully",
          "success",
        );
      },
      onError: () => {
        addFlag("Failed to clear API key", "Failed to clear API key", "error");
      },
    });
    await refetch();
  };

  return (
    <PageContent>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs onExpand={_.noop}>
            <BreadcrumbsItem text="Configuration" key="Configuration" />
          </Breadcrumbs>
        }
        actions={
          <ButtonGroup>
            <Button
              isDisabled={mutationPending || isLoading}
              appearance="subtle"
              onClick={() => {
                refetch();
              }}
            >
              Refresh
            </Button>
            {configStatus?.apiKeyProvided && (
              <ClearApiAction
                isMutationPending={mutationPending}
                clearConfig={handleClearApiKey}
              />
            )}
          </ButtonGroup>
        }
      >
        Configuration
      </PageHeader>
      <CenteredContainer maxWidth={"960px"}>
        <Stack
          space="space.150"
          xcss={xcss({
            paddingTop: "space.050",
            paddingBottom: "space.250",
          })}
        >
          <Box>
            Each user of the Admin Automation app needs an API key. This allow
            the automation rules to be run on your behalf. It is used to perform
            basic authentication within Atlassian Cloud products.
          </Box>
          <Box>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://support.atlassian.com/organization-administration/docs/manage-an-organization-with-the-admin-apis/"
            >
              Learn more about how to create an API key{" "}
              <ShortcutIcon label="External link" size="small" />
            </a>
          </Box>
        </Stack>
        <Box>
          {isError && !isLoading && <ErrorSection />}
          {!configStatus && isLoading ? (
            <LoadingTracker />
          ) : (
            <>
              {configStatus?.apiKeyProvided ? (
                <>
                  <CustomSectionMessage
                    title={"API key has been provided"}
                    appearance={
                      configStatus?.status === "AUTHORIZED"
                        ? "success"
                        : "warning"
                    }
                  >
                    {configStatus?.status === "UNAUTHORIZED"
                      ? "Unfortunately, it is no longer authorized. Please clear this key so you can reconfigure a new API key."
                      : "API key has been provided!"}
                  </CustomSectionMessage>
                  <Inline
                    space="space.075"
                    alignBlock="center"
                    alignInline="center"
                  >
                    {configStatus.createdDate && (
                      <small>
                        Created {moment(configStatus.createdDate).format("LLL")}
                      </small>
                    )}
                    {configStatus.createdBy && (
                      <>
                        <small>by </small>
                        <small>
                          <Profile
                            atlassianAccountId={configStatus.createdBy}
                            showName
                            size="medium"
                          />
                        </small>
                      </>
                    )}
                  </Inline>
                </>
              ) : (
                <>
                  <Stack space="space.075">
                    <CustomSectionMessage
                      title="API key is empty or invalid"
                      appearance="error"
                    >
                      <Stack space="space.150">
                        <Box>
                          An API key is required to make your wonderful, time
                          and money saving automations work. Please create a new
                          API and enter it into the field
                        </Box>
                        <Box>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://support.atlassian.com/organization-administration/docs/manage-an-organization-with-the-admin-apis/#Create-an-API-key"
                          >
                            Create an API key{" "}
                            <ShortcutIcon label="External link" size="small" />
                          </a>
                        </Box>
                      </Stack>
                    </CustomSectionMessage>
                    <Form onSubmit={handleSaveApiKey}>
                      {({ formProps }: any) => (
                        <form {...formProps} className="form-container">
                          <Stack space="space.150">
                            <Field label="API Key" name="apiKey" isRequired>
                              {({ fieldProps }: any) => (
                                <Fragment>
                                  <TextField
                                    type="password"
                                    placeholder="Enter your API key here"
                                    {...fieldProps}
                                  />
                                </Fragment>
                              )}
                            </Field>
                            <Box>
                              <Button type="submit" appearance="primary">
                                Save
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      )}
                    </Form>
                  </Stack>
                </>
              )}
            </>
          )}
        </Box>
      </CenteredContainer>
    </PageContent>
  );
};

export default Configuration;
