import React from "react";
import Avatar, { Skeleton } from "@atlaskit/avatar";
import "./Profile.css";
import { useUserQuery } from "../../clients/jira/jira-queries";

interface ProfileProps {
  atlassianAccountId: string;
  size: "small" | "medium" | "large";
  showName?: boolean;
  showAvatar?: boolean;
}

const Profile: React.FC<ProfileProps> = ({
  atlassianAccountId,
  size,
  showName,
  showAvatar,
}) => {
  const { data: user, error, isLoading } = useUserQuery(atlassianAccountId);

  var pixelSize = "48x48";
  switch (size) {
    case "small":
      pixelSize = "24x24";
      break;
    case "medium":
      pixelSize = "32x32";
      break;
  }

  return (
    <div className="profile">
      {user ? (
        <>
          {showAvatar && (
            <span className="profile__avatar">
              <Avatar
                src={user.avatarUrls[pixelSize]}
                size={size}
                name={user.displayName}
              />
            </span>
          )}
          {showName && (
            <div className="profile__name">
              {user.displayName}
              {user.emailAddress && (
                <>
                  <br />
                  <small>{user.emailAddress}</small>
                </>
              )}
            </div>
          )}
        </>
      ) : isLoading ? (
        <>
          <Skeleton appearance="circle" /> loading...
        </>
      ) : error ? (
        <>
          <small>{atlassianAccountId}</small>
        </>
      ) : (
        <>
          <Skeleton appearance="circle" />
        </>
      )}
    </div>
  );
};

export default Profile;
