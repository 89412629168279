import React from "react";
import Select from "@atlaskit/select";
import { OptionType, ValueType } from "@atlaskit/select";
import { ErrorMessage, Field } from "@atlaskit/form";
import { AutomationSchedule } from "../../../types";
import _ from "lodash";

interface ScheduleSelectProps {
  defaultValue?: AutomationSchedule;
}

const scheduleOptions: OptionType[] = [
  { label: "Run Once", value: "MANUAL" },
  { label: "Hourly", value: "HOURLY" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const ScheduleSelect: React.FC<ScheduleSelectProps> = ({ defaultValue }) => {
  return (
    <>
      <Field<ValueType<OptionType, false>>
        name="schedule"
        label="Frequency"
        validate={(value) => {
          return value?.value ? undefined : "Frequency is required";
        }}
        defaultValue={_.find(scheduleOptions, {
          value: defaultValue || "DAILY",
        })}
        isRequired
      >
        {({ fieldProps: { id, ...rest }, error }) => (
          <>
            <Select inputId={id} {...rest} options={scheduleOptions} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>
    </>
  );
};

export default ScheduleSelect;
