import React, { useCallback, useState } from "react";
import { LoadingButton as Button } from "@atlaskit/button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";

export const ClearApiAction = ({
  clearConfig,
  isMutationPending,
}: {
  isMutationPending: boolean;
  clearConfig: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDeleteModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  return (
    <>
      <Button
        isLoading={isMutationPending}
        isDisabled={isMutationPending}
        appearance="warning"
        onClick={openDeleteModal}
      >
        Clear
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="warning">Warning</ModalTitle>
            </ModalHeader>
            <ModalBody>
              Deleting your API key will mean all the automation rules you own
              will no longer function. Are you sure?
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                appearance="warning"
                onClick={() => {
                  closeModal();
                  clearConfig();
                }}
              >
                Delete API key
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
