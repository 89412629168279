import EmptyState from "@atlaskit/empty-state";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <EmptyState
      header="Sorry, an unexpected error has occurred."
      description={error.statusText || error.message}
    />
  );
}
