import React, { ReactNode } from "react";
import { Box, xcss } from "@atlaskit/primitives";

export interface WrapperProps {
  maxWidth?: string;
  children: ReactNode;
}

export const CenteredContainer: React.FC<WrapperProps> = ({
  maxWidth,
  children,
}) => {
  const centerBoxCss = xcss({
    width: "100%",
    maxWidth: maxWidth || "960px",
    margin: "0 auto",
  });
  return <Box xcss={centerBoxCss}>{children}</Box>;
};

export default CenteredContainer;
