import moment from "moment";
import { Automation, AutomationListItem } from "../../types/Automation";
import Lozenge from "@atlaskit/lozenge";
import _ from "lodash";

export function nextRun(
  automation: Automation | undefined | AutomationListItem,
) {
  if (!automation || !automation.enabled)
    return <Lozenge appearance="moved">Disabled</Lozenge>;
  if (automation.running)
    return <Lozenge appearance="inprogress">in progress</Lozenge>;
  if (automation.startDate && moment(automation.startDate).isAfter(moment()))
    return moment(automation.startDate).fromNow();
  if (automation.nextRun) {
    const nextRun = moment(automation.nextRun);
    if (nextRun.isBefore(moment().add(1, "minute"))) {
      return <Lozenge appearance="new">pending</Lozenge>;
    }
    return (
      <>
        {nextRun.calendar()}
        <br />
        <small>{_.capitalize(nextRun.fromNow())}</small>
      </>
    );
  }
  return "Unknown";
}
